let menu = null,
    menuWraps = Array();

function toggleMenu() {

    if (menu.classList.contains('active')) {
        document.querySelector('.dropdown-btn').classList.remove('active');
        menu.classList.remove('active');
        document.body.classList.remove('hidden');
    } else {
        document.querySelector('.dropdown-btn').classList.add('active');
        menu.classList.add('active');
        document.body.classList.add('hidden');
    }
}

function showHover(itemID = null) {
    menuWraps.forEach(wrap => {
        wrap.classList.remove('active');
    });

    if (itemID) {
        document.querySelector('.js-wrap[data-wrap="' + itemID + '"]').classList.add('active');
    } else {
        document.querySelector('.js-wrap[data-wrap="default"]').classList.add('active');
    }
}

document.addEventListener('click', function(e){
    const {target} = e;

    if (target.closest('.dropdown-btn, .dropdown-btn-hide')) toggleMenu()

    if (target.closest('.organize-js')) generateModal ({title: 'Организовать турнир', content: {banner: "https://ru-wotp.lesta.ru/dcont/fb/image/kontribyutory_1920h900.jpg", html: "<p>Для организаций, турнирных операторов, брендов и блогеров — мы открыты к диалогу по созданию контента, турниров, лиг, поддержке мероприятий, иных событий как совместных, так и именно ваших отдельных. Присылайте своё предложение на почту:  <b>tournaments@lesta.group</b></p>"}})

    const withinBoundaries = e.composedPath().includes(menu);
    if ( !withinBoundaries && !target.closest('.dropdown-btn') && menu.classList.contains('active')) toggleMenu();
})

window.addEventListener('DOMContentLoaded', function(e) {
    menu = document.querySelector('.nav-js');

    const menuItems = document.querySelectorAll('.js-hover');
    menuWraps = document.querySelectorAll('.js-wrap');

    menuItems.forEach(item => {
        item.addEventListener('mouseenter', debounce(function() {
            const itemID = item.getAttribute('data-wrap');
            showHover(itemID);
        }), 1000);
        item.addEventListener('mouseleave', debounce(function() {
            showHover();
        }), 1000)
    });
});